import styled from '@emotion/styled';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {rhythm, scale} from '../utils/typography';

interface LinkProps {
  to: string;
  title: string;
  children?: any;
}

const Projects = () => {
  const ProjectLink = ({to, title, children}: LinkProps) => (
    <div>
      <A href={to} target="_blank">
        {title}
      </A>
      <Div>{children}</Div>
    </div>
  );

  return (
    <Layout>
      <SEO title="Projects" />
      <ProjectLink to="https://gitlab.com/testbrew/website" title="TestBrew Website">
        This awesome!! Website.
      </ProjectLink>
      <ProjectLink to="https://github.com/roygrssmn/git-spell-check" title="Git spell check">
        Git hook for spelling check
      </ProjectLink>
      <ProjectLink
        to="https://github.com/roygrssmn/spock-annotations"
        title="Using spock annotations"
      >
        Using spock annotations for running tests suites (smoke/regression/flaky/etc)
      </ProjectLink>
      <ProjectLink
        to="https://gitlab.com/testbrew/java-selenium-example"
        title="Java Selenium example"
      >
        An example project for using Java and Selenium to run tests on Google Maps. Tests examples
        included.
      </ProjectLink>
      <ProjectLink to="https://gitlab.com/testbrew/name-splitter" title="Groovy Spock example">
        An example project for using Groovy and Spock test framework to split user names. Tests
        examples included.
      </ProjectLink>
      <ProjectLink
        to="https://gitlab.com/testbrew/seleniumbase-example"
        title="SeleniumBase visual inspection (Website + PDF) example"
      >
        A small proof of concept using GitLab and SeleniumBase (Python) to run visual inspection on
        the Website and PDF documents. I have created a simple project for you to use which is based
        on the SeleniumBase examples.
      </ProjectLink>
      {/*       <ProjectLink to="https://gitlab.com/testbrew/release-tracker" title="Release Tracker"> */}
      {/*         Uses GitLab API and Jira API to track the repository content and be an automated quality */}
      {/*         gate. (Under construction 🚧) */}
      {/*       </ProjectLink> */}
      <ProjectLink
        to="https://gitlab.com/testbrew/job-trigger-automation"
        title="Job Trigger Automation"
      >
        Used GitLab API to trigger jobs.
      </ProjectLink>
      <ProjectLink
        to="https://gitlab.com/testbrew/slack-announcement"
        title="Slack Announcement automation"
      >
        Used Slack API to send Webhooks. The project is so you can reuse the script on all other
        projects.
      </ProjectLink>
    </Layout>
  );
};

export default Projects;

const A = styled.a`
  ${scale(1 / 5)};
  display: inline-block;
  margin-right: 1rem;
`;

const Div = styled.div`
  margin-bottom: ${rhythm(4 / 3)};
  margin-left: 0.25rem;
`;
